<template>
    <section class="technology">
        <div class="position-relative container py-5">
            <div class="position-absolute pattern">
                <svg id="pattern-5"
                     class="h-100"
                     v-bind:class="{ 'done': isPatternVivusDone }"
                     version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72.0644684 251.4155579" xml:space="preserve">
                    <path fill="transparent" stroke="#B7C4D5" d="M44.3726006,13.910265
                        c-0.0411072,2.5925293,0.0523376,5.1844788,0.334137,7.7450562c0.3056641,2.5530396,0.8351746,5.0756226,1.5214844,7.5568848
                        c1.3977051,4.9604778,3.3530884,9.7530804,5.1029053,14.6488018c3.495575,9.785614,5.9382935,20.0871277,6.0700073,30.5995789
                        c0.0329895,2.6224976-0.1007385,5.2506409-0.4094543,7.8606567c-0.2904663,2.6109924-0.7471924,5.2110291-1.3794861,7.7575073
                        c-1.2456055,5.1025085-3.0365906,10.0200806-4.8746948,14.872406c-1.8363342,4.8535156-3.7428589,9.6618042-5.2196655,14.5725098
                        c-1.474762,4.9075928-2.5371704,9.9396362-2.7756042,15.0063477c-0.2420654,5.0669556,0.5048218,10.1322632,2.4442444,14.8172607
                        c1.890625,4.7163086,4.6341858,9.1096191,7.0857239,13.7177734c4.9780884,9.2349243,7.9499207,19.5193481,8.8415833,29.9372559
                        c0.9337463,10.4202881-0.1421204,20.9891357-3.0561523,30.996582c-2.9760132,9.9839478-7.8204956,19.4290771-14.4468994,27.416687
                        c6.2819519-8.2503052,10.8947449-17.7061157,13.5423584-27.6721802c2.6679077-9.9514771,3.5791626-20.378479,2.4786377-30.5982666
                        c-1.0984497-10.2074585-4.0263977-20.2275391-8.9424438-29.2321777c-2.4347229-4.524292-5.2417297-8.9469604-7.2479553-13.8594971
                        c-2.065155-4.897644-2.8981018-10.3238525-2.6669922-15.6048584c0.1954346-5.3045654,1.3548584-10.4884033,2.8835144-15.4896851
                        c1.5388794-5.0126953,3.4873962-9.841095,5.3445435-14.6707153c1.8595581-4.8262939,3.6484375-9.6642761,4.8902893-14.6337585
                        c0.6307983-2.4814148,1.0860291-4.9956055,1.382782-7.5335999c0.3170471-2.5353394,0.4536743-5.0943909,0.4467163-7.6544189
                        c0.0458374-10.2621155-2.178833-20.4649963-5.4833679-30.2249146c-1.6586609-4.8898315-3.5550842-9.7471008-4.8122253-14.8178692
                        c-0.6061401-2.5341797-1.1458435-5.1050415-1.2618103-7.7221375C44.0167961,19.0959339,44.1067619,16.4909229,44.3726006,13.910265z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M17.2425041,212.1087494
                        c0.8459473-2.8274536,0.6954956-5.776062,0.7350769-8.7015991c0.0501404-2.9428101,0.3612976-5.897644,0.9543152-8.7928467
                        c1.2203979-5.7920532,3.3296509-11.3251953,5.3985596-16.7941895c2.0589294-5.4758911,4.1029968-10.9324951,5.2225037-16.5968628
                        c1.1596375-5.6613159,1.5958252-11.4688721,1.7858887-17.2905273c0.2054443-5.8233032,0.2466736-11.6929932,0.3725891-17.5437012
                        l0.3287048-17.5581055c0.1653442-11.7041016,0.2695923-23.4075012,0.0791931-35.1256409
                        c0.9671021,11.6806641,1.3857117,23.409668,1.4815063,35.1303101c0.0940247,11.7313538,0.0085754,23.419342-0.2644348,35.1682434
                        c-0.2311401,5.8737793-0.678009,11.7961426-1.9351501,17.6063843c-1.2639465,5.8215942-3.3937988,11.368103-5.6235657,16.7778931
                        c-2.1968994,5.4265747-4.4710388,10.8069458-5.8957825,16.4440918c-0.726593,2.8132324-1.178772,5.6859131-1.377533,8.5938721
                        C18.3361626,206.3247528,18.2971001,209.3468475,17.2425041,212.1087494z"></path>
                    <path fill="transparent" stroke="#B7C4D5" stroke-miterlimit="10" d="M64.8756866,166.7768402
                        c0.5807724-13.7223511,1.28936-27.4371338,1.9304733-41.1558838l2.1390991-41.1436462l1.1112061-20.5693359
                        c0.178009-3.4364014,0.5113831-6.842926-0.0862427-10.05896c-0.295166-1.5932312-0.8490906-3.1176758-1.7830505-4.3467407
                        c-0.9182434-1.244812-2.2047424-2.1589355-3.680748-2.6693115c-1.4733009-0.5194092-3.0969887-0.6630554-4.7018776-0.5339966
                        c-1.6159973,0.1333008-3.1834412,0.5263977-4.7178955,1.1492004c-3.0793152,1.2148743-5.9283142,3.1066895-8.9964905,4.7653198
                        c-6.2060852,3.385437-13.0654907,4.9205017-19.5545654,6.9261169c-3.2085571,0.9751587-6.4305725,2.1700134-8.3858948,4.5978699
                        c-1.9642639,2.4204407-2.3825064,5.8300476-2.4073782,9.1561584c0.0921631,6.806366,0.286437,13.7132874,0.4246511,20.5649719
                        c0.3391418,13.7326355,0.4349976,27.4611816,1.4501343,41.1226196c0.4955444,6.8377075,1.058075,13.7398071,0.6405334,20.6624756
                        c-0.4526062,6.918396-1.8346252,13.7075195-3.4656668,20.3813477c-1.6445313,6.6760254-3.4986267,13.2853394-4.8062134,20.0139771
                        c-1.3495789,6.7119751-2.0227666,13.5889893-1.4963379,20.4439697c-0.7433476-6.8336792-0.2509155-13.7757568,0.9750061-20.5395508
                        c1.1654358-6.7850342,2.8795166-13.4515991,4.3730164-20.142334c1.5233154-6.6793823,2.8084402-13.4219971,3.158782-20.2254028
                        c0.3201904-6.8079224-0.3241577-13.6331787-0.9127808-20.4732666c-0.6225271-6.8508301-0.968627-13.7351685-1.1736441-20.6032715
                        l-0.562561-20.596405c-0.1687927-6.8774109-0.3926392-13.697937-0.5161743-20.6220398
                        c0.015686-1.7501526,0.1035156-3.5366211,0.4979858-5.3198547c0.3735962-1.7771912,1.111969-3.5582275,2.2940359-5.0475464
                        c1.1869812-1.4915161,2.7546387-2.5823364,4.3664551-3.3916321c1.6202698-0.8132019,3.3023682-1.3841858,4.9612732-1.8922729
                        c6.6065979-2.0018311,13.315033-3.4900208,19.2369385-6.696167c2.9752808-1.5844727,5.8887634-3.5142517,9.2176819-4.8180237
                        c3.3103333-1.3076477,7.1428833-1.8376465,10.7083092-0.5945435c1.765625,0.61203,3.4000549,1.7791138,4.511322,3.3084717
                        c1.1405029,1.519165,1.7661743,3.3254395,2.0866699,5.0949707c0.6385498,3.5733948,0.2507629,7.0871887,0.0680542,10.4851074
                        l-1.3057556,20.5579224l-2.6948853,41.1109924C66.8089371,139.3775482,65.9061966,153.0809174,64.8756866,166.7768402z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M15.0695925,85.8997345
                        c-0.5389709,1.5906067-1.2724609,3.1176758-2.0866394,4.5898438c-0.8106689,1.4767151-1.6882019,2.916687-2.6286011,4.3208923
                        c-1.8633118,2.8473816-4.2578435,5.2835999-6.0751958,7.978241c-0.909668,1.3518372-1.6551816,2.7695313-2.068512,4.2721558
                        c-0.4257813,1.4971924-0.552765,3.0830383-0.2355042,4.536438c0.3174438,1.47995,1.085083,2.6601868,2.2835693,3.6897888
                        c1.1588135,1.050354,2.7086487,2.0571899,3.7345276,3.7523804c0.4949956,0.8182983,0.8218694,1.6828003,1.0767827,2.5212402
                        c0.2615356,0.8392944,0.4542236,1.6509399,0.6635742,2.4805908c0.4197998,1.6490479,0.7511292,3.3170776,1.0443115,4.9887085
                        c0.5429077,3.3511353,0.8779602,6.7252808,1.0017395,10.0979004c0.2973022,6.7484741-0.1115417,13.4573364-0.565155,20.1517944
                        c-0.3432922-6.7062378-0.474823-13.414978-1.0206604-20.0508423c-0.2938843-3.3157959-0.6688232-6.6176758-1.3026123-9.8660278
                        c-0.3014526-1.6257935-0.6389771-3.2444458-1.061646-4.8390503c-0.413147-1.6003418-0.8224182-3.2242432-1.5417175-4.4631958
                        c-0.7019348-1.2283325-2.0158691-2.1537476-3.3290713-3.3335571c-0.6499326-0.5917358-1.3125305-1.2832031-1.8076172-2.1138916
                        c-0.4927062-0.8228149-0.8143004-1.7323608-0.987152-2.6488647c-0.6758423-3.7105713,0.8235474-7.3259583,2.9103394-10.0283813
                        c2.0657046-2.7802734,4.5988159-4.976593,6.6016245-7.6183777C11.711133,91.687088,13.6186037,88.9222565,15.0695925,85.8997345z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M44.748394,1.857744
                        c1.13974-0.9041138,2.4199829-1.8807678,4.0753784-1.8573303c0.8161621,0.0237427,1.625,0.4330139,2.1564331,1.0979614
                        c0.5237122,0.6743773,0.7443237,1.4970398,0.8351135,2.2821352c0.1512451,1.5971375-0.1761169,3.0970154-0.4306641,4.5671387
                        c-0.287323,1.484436-0.7347717,3.022583-1.6697388,4.3980713c-0.9269714,1.3336792-2.3591614,2.4981079-4.184082,2.7206726
                        c-0.4655457,0.0546875-0.8863525,0.0230408-1.1418152,0.0484314c-0.2844849,0.0332031-0.3039551,0.0690002-0.3535156,0.1286621
                        c-0.1517639,0.1328125-0.3132324,1.0275879-0.4852905,1.7286987c-0.343811,1.4558105-0.756897,2.9419556-1.3988342,4.3908997
                        c-0.6287231,1.4443359-1.6072693,2.8393555-2.7523499,3.9145813c-4.5624084,4.1381531-10.1458435,6.9414959-16.0766602,8.2127972
                        c-1.4826355,0.3042297-2.9783325,0.5360413-4.4829407,0.6561584c-0.7536926,0.0462036-1.5056763,0.0925598-2.2595215,0.0923767
                        c-0.7182608-0.0075073-1.5690908,0.1093445-2.4443045-0.3728027c-0.4214478-0.2510681-0.7201233-0.6611633-0.8809204-1.0635681
                        c-0.1625061-0.4060974-0.2273254-0.8091717-0.2655029-1.1961956c-0.0592041-0.7739563-0.0745239-1.4903564-0.1139526-2.2383423
                        c-0.0763245-1.4802246-0.1282654-2.9628296-0.1628113-4.4470825c0.2498474,1.4634705,0.4822998,2.9286499,0.6903687,4.3961792
                        c0.1122131,0.724823,0.2085266,1.4857178,0.3338013,2.1809387c0.1273193,0.6910992,0.4031982,1.3135357,0.8746033,1.5107098
                        c0.4638062,0.2242432,1.1788635,0.1546021,1.9148855,0.0830383c0.7266846-0.0527039,1.4484558-0.1510925,2.1686707-0.2480774
                        c1.4370117-0.2228088,2.8624268-0.4988708,4.2635498-0.8709393c1.3968201-0.3878174,2.77005-0.8457947,4.1195984-1.3618774
                        c1.3439331-0.5322876,2.6516418-1.1484375,3.9223328-1.8294067c2.5253296-1.3599548,4.9551697-3.0260315,7.0142822-4.9455261
                        c1.0206299-0.9491882,1.7825623-2.0169678,2.3591309-3.279541c0.5802307-1.2530823,0.9629517-2.6344604,1.3455811-4.0461731
                        l0.2852783-1.0750122c0.1116638-0.3841858,0.2311401-0.8724365,0.6459045-1.3695374
                        c0.2050171-0.2428894,0.507843-0.4582214,0.8067017-0.5679321c0.3012085-0.1170959,0.5787964-0.1479187,0.8164673-0.1622314
                        c0.4838257-0.0182495,0.8052368,0.0205383,1.0774841-0.007782c1.1565247-0.0984497,2.3057251-0.8631592,3.1075745-1.8980103
                        c0.8049316-1.040863,1.280365-2.3876953,1.6548767-3.7758484c0.3593445-1.4153137,0.7528992-2.834198,0.729126-4.2111511
                        c-0.0092468-0.6843262-0.1305237-1.3594666-0.4705811-1.9039612c-0.3448792-0.5319824-0.9283752-0.9260558-1.5961914-1.0134888
                        c-0.6694336-0.1059265-1.3881226,0.0739746-2.0610657,0.3317871C46.0337639,1.1148241,45.381237,1.4716356,44.748394,1.857744z"></path>
                </svg>
            </div>

            <h1 class="position-relative text-center font-weight-bold mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                脂肪凋亡技術
            </h1>

            <h3 class="position-relative text-center mb-4"
                data-aos="fade-up"
                data-aos-duration="1000">
                由表層到脂肪底層 逐層均匀射頻能量導入<br>
                抵達脂肪每層組織層 凋亡脂肪細胞 效果持久
            </h3>

            <div class="position-relative row mb-3"
                 data-aos="fade-up"
                 data-aos-duration="1000">
                <div class="col-md-5 d-flex justify-content-center justify-content-md-end">
                    <svg class="icon mb-2 mb-md-0" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 283.4645691 283.4645691" enable-background="new 0 0 283.4645691 283.4645691" xml:space="preserve">
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="141.7322845" y1="2.5254781" x2="141.7322845" y2="279.3291016">
                            <stop  offset="0.2229202" style="stop-color:#FCDFD6"></stop>
                            <stop  offset="0.3388689" style="stop-color:#FDEBE6"></stop>
                            <stop  offset="0.517253" style="stop-color:#FFFAF8"></stop>
                            <stop  offset="0.6432572" style="stop-color:#FFFFFF"></stop>
                        </linearGradient>
                        <circle fill="url(#SVGID_1_)" cx="141.7322845" cy="140.9272919" r="138.401825"></circle>
                        <g>
                            <defs>
                                <circle id="SVGID_2_" cx="141.7322845" cy="140.9272919" r="138.401825"></circle>
                            </defs>
                            <clipPath id="SVGID_3_">
                                <use xlink:href="#SVGID_2_"  overflow="visible"></use>
                            </clipPath>
                            <g clip-path="url(#SVGID_3_)">
                                <g>
                                    <rect x="-46.1863251" y="160.0366211" fill="#E8C3BF" width="632.1829834" height="65.6251144"></rect>
                                    <rect x="-49.8338127" y="156.579361" fill="#CF787A" width="632.1829834" height="4.684772"></rect>
                                    <g>
                                        <g>
                                            <path fill="#FFEC9E" d="M-23.4465733,180.8464508h-48.1380157c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499985-3,3-3h48.1380157c1.6499996,0,3,1.3500061,3,3v9.5851898
                                                C-20.4465733,179.4964447-21.7965736,180.8464508-23.4465733,180.8464508z"></path>
                                            <path fill="#FFFFFF" d="M28.2993946,178.0325775H-0.2896175c-1.65,0-3-1.3500061-3-3v-3.9574432c0-1.6499939,1.35-3,3-3
                                                h28.5890121c1.6499996,0,3,1.3500061,3,3v3.9574432C31.2993946,176.6825714,29.9493942,178.0325775,28.2993946,178.0325775z"></path>
                                            <path fill="#FFFFFF" d="M90.0934219,178.0325775H61.5044098c-1.6499977,0-3-1.3500061-3-3v-3.9574432
                                                c0-1.6499939,1.3500023-3,3-3h28.5890121c1.6500015,0,3,1.3500061,3,3v3.9574432
                                                C93.0934219,176.6825714,91.7434235,178.0325775,90.0934219,178.0325775z"></path>
                                            <path fill="#FFFFFF" d="M151.6138916,178.0325775h-28.5890121c-1.6500015,0-3-1.3500061-3-3v-3.9574432
                                                c0-1.6499939,1.3499985-3,3-3h28.5890121c1.6499939,0,3,1.3500061,3,3v3.9574432
                                                C154.6138916,176.6825714,153.2638855,178.0325775,151.6138916,178.0325775z"></path>
                                            <path fill="#FFEC9E" d="M222.6350555,180.8464508h-48.1380157c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C225.6350555,179.4964447,224.2850494,180.8464508,222.6350555,180.8464508z"></path>
                                            <path fill="#FFFFFF" d="M277.7595215,179.0051727h-35.3460236c-1.6499939,0-3-1.3499908-3-3v-5.9026337
                                                c0-1.6500092,1.3500061-3,3-3h35.3460236c1.6499939,0,3,1.3499908,3,3v5.9026337
                                                C280.7595215,177.6551819,279.4095154,179.0051727,277.7595215,179.0051727z"></path>
                                            <path fill="#FFFFFF" d="M336.1750488,178.0325775h-28.5890198c-1.6499939,0-3-1.3500061-3-3v-3.9574432
                                                c0-1.6499939,1.3500061-3,3-3h28.5890198c1.6499939,0,3,1.3500061,3,3v3.9574432
                                                C339.1750488,176.6825714,337.8250427,178.0325775,336.1750488,178.0325775z"></path>
                                            <path fill="#FFEC9E" d="M407.4700012,180.8464508h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C410.4700012,179.4964447,409.1200256,180.8464508,407.4700012,180.8464508z"></path>
                                            <path fill="#FFEC9E" d="M468.1704712,180.8464508h-48.138031c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C471.1704712,179.4964447,469.8204651,180.8464508,468.1704712,180.8464508z"></path>
                                            <path fill="#FFFFFF" d="M523.2949219,179.0051727h-35.3460083c-1.6499939,0-3-1.3499908-3-3v-5.9026337
                                                c0-1.6500092,1.3500061-3,3-3h35.3460083c1.6500244,0,3,1.3499908,3,3v5.9026337
                                                C526.2949219,177.6551819,524.9449463,179.0051727,523.2949219,179.0051727z"></path>
                                            <path fill="#FFEC9E" d="M591.4849243,180.8464508h-48.1380005c-1.6499634,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500366-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C594.4849243,179.4964447,593.1349487,180.8464508,591.4849243,180.8464508z"></path>
                                            <path fill="#FFEC9E" d="M653.0054321,180.8464508h-48.1380005c-1.6500244,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499756-3,3-3h48.1380005c1.6499634,0,3,1.3500061,3,3v9.5851898
                                                C656.0054321,179.4964447,654.6553955,180.8464508,653.0054321,180.8464508z"></path>
                                        </g>
                                        <g>
                                            <path fill="#FFEC9E" d="M-23.4465733,221.9967499h-48.1380157c-1.6500015,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3499985-3,3-3h48.1380157c1.6499996,0,3,1.3500061,3,3v9.5851898
                                                C-20.4465733,220.646759-21.7965736,221.9967499-23.4465733,221.9967499z"></path>
                                            <path fill="#FFEC9E" d="M38.0738945,221.9967499h-48.1380119c-1.6499996,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500004-3,3-3h48.1380119c1.6500015,0,3,1.3500061,3,3v9.5851898
                                                C41.0738945,220.646759,39.723896,221.9967499,38.0738945,221.9967499z"></path>
                                            <path fill="#FFFFFF" d="M92.1267242,219.768219H59.4711113c-1.6499977,0-3-1.3499908-3-3v-5.1281281
                                                c0-1.6499939,1.3500023-3,3-3h32.6556129c1.6499939,0,3,1.3500061,3,3v5.1281281
                                                C95.1267242,218.4182281,93.7767181,219.768219,92.1267242,219.768219z"></path>
                                            <path fill="#FFEC9E" d="M161.3883972,221.9967499h-48.1380157c-1.6500015,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3499985-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C164.3883972,220.646759,163.0383911,221.9967499,161.3883972,221.9967499z"></path>
                                            <path fill="#FFEC9E" d="M222.6350555,221.9967499h-48.1380157c-1.6499939,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C225.6350555,220.646759,224.2850494,221.9967499,222.6350555,221.9967499z"></path>
                                            <path fill="#FFFFFF" d="M276.4143066,219.768219h-32.6555939c-1.6500092,0-3-1.3499908-3-3v-5.1281281
                                                c0-1.6499939,1.3499908-3,3-3h32.6555939c1.6500244,0,3,1.3500061,3,3v5.1281281
                                                C279.4143066,218.4182281,278.0643311,219.768219,276.4143066,219.768219z"></path>
                                            <path fill="#FFEC9E" d="M345.9495544,221.9967499h-48.138031c-1.6499939,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C348.9495544,220.646759,347.5995483,221.9967499,345.9495544,221.9967499z"></path>
                                            <path fill="#FFFFFF" d="M399.7288208,219.768219h-32.6556091c-1.6499939,0-3-1.3499908-3-3v-5.1281281
                                                c0-1.6499939,1.3500061-3,3-3h32.6556091c1.6499939,0,3,1.3500061,3,3v5.1281281
                                                C402.7288208,218.4182281,401.3788147,219.768219,399.7288208,219.768219z"></path>
                                            <path fill="#FFEC9E" d="M468.1704712,221.9967499h-48.138031c-1.6499939,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C471.1704712,220.646759,469.8204651,221.9967499,468.1704712,221.9967499z"></path>
                                            <path fill="#FFEC9E" d="M529.690918,221.9967499h-48.1380005c-1.6499939,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C532.690918,220.646759,531.3409424,221.9967499,529.690918,221.9967499z"></path>
                                            <path fill="#FFEC9E" d="M591.4849243,221.9967499h-48.1380005c-1.6499634,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3500366-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C594.4849243,220.646759,593.1349487,221.9967499,591.4849243,221.9967499z"></path>
                                            <path fill="#FFEC9E" d="M653.0054321,221.9967499h-48.1380005c-1.6500244,0-3-1.3499908-3-3v-9.5851898
                                                c0-1.6499939,1.3499756-3,3-3h48.1380005c1.6499634,0,3,1.3500061,3,3v9.5851898
                                                C656.0054321,220.646759,654.6553955,221.9967499,653.0054321,221.9967499z"></path>
                                        </g>
                                        <g>
                                            <path fill="#FFEC9E" d="M8.5440702,201.3532715h-48.1380119c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499985-3,3-3H8.5440702c1.6499996,0,3,1.3500061,3,3v9.5851898
                                                C11.5440702,200.0032654,10.1940699,201.3532715,8.5440702,201.3532715z"></path>
                                            <path fill="#FFEC9E" d="M70.064537,201.3532715H21.9265251c-1.6499996,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500004-3,3-3H70.064537c1.6500015,0,3,1.3500061,3,3v9.5851898
                                                C73.064537,200.0032654,71.7145386,201.3532715,70.064537,201.3532715z"></path>
                                            <path fill="#FFEC9E" d="M131.8585663,201.3532715H83.7205582c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499985-3,3-3h48.1380081c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C134.8585663,200.0032654,133.5085602,201.3532715,131.8585663,201.3532715z"></path>
                                            <path fill="#FFFFFF" d="M183.7458496,198.5800781h-28.8716431c-1.6500092,0-3-1.3499908-3-3v-4.0388031
                                                c0-1.6499939,1.3499908-3,3-3h28.8716431c1.6500092,0,3,1.3500061,3,3v4.0388031
                                                C186.7458496,197.2300873,185.3958588,198.5800781,183.7458496,198.5800781z"></path>
                                            <path fill="#FFEC9E" d="M254.6257019,201.3532715h-48.1380157c-1.6500092,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499908-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C257.6257019,200.0032654,256.2756958,201.3532715,254.6257019,201.3532715z"></path>
                                            <path fill="#FFEC9E" d="M316.1461487,201.3532715h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C319.1461487,200.0032654,317.7961731,201.3532715,316.1461487,201.3532715z"></path>
                                            <path fill="#FFEC9E" d="M377.9401855,201.3532715h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C380.9401855,200.0032654,379.5901794,201.3532715,377.9401855,201.3532715z"></path>
                                            <path fill="#FFEC9E" d="M439.4606628,201.3532715h-48.138031c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                C442.4606628,200.0032654,441.1106567,201.3532715,439.4606628,201.3532715z"/>
                                            <path fill="#FFFFFF" d="M490.5279236,198.5800781h-28.8716431c-1.6500244,0-3-1.3499908-3-3v-4.0388031
                                                c0-1.6499939,1.3499756-3,3-3h28.8716431c1.6499939,0,3,1.3500061,3,3v4.0388031
                                                C493.5279236,197.2300873,492.1779175,198.5800781,490.5279236,198.5800781z"/>
                                            <path fill="#FFEC9E" d="M561.6815796,201.3532715h-48.1380005c-1.6500244,0-3.0000305-1.3500061-3.0000305-3v-9.5851898
                                                c0-1.6499939,1.3500061-3,3.0000305-3h48.1380005c1.6499634,0,3,1.3500061,3,3v9.5851898
                                                C564.6815796,200.0032654,563.331543,201.3532715,561.6815796,201.3532715z"/>
                                            <path fill="#FFEC9E" d="M623.4755859,201.3532715h-48.1380005c-1.6500244,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3499756-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                C626.4755859,200.0032654,625.1256104,201.3532715,623.4755859,201.3532715z"/>
                                            <path fill="#FFEC9E" d="M684.9960938,201.3532715h-48.1380615c-1.6499634,0-3-1.3500061-3-3v-9.5851898
                                                c0-1.6499939,1.3500366-3,3-3h48.1380615c1.6499634,0,3,1.3500061,3,3v9.5851898
                                                C687.9960938,200.0032654,686.6460571,201.3532715,684.9960938,201.3532715z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <circle fill="#CFE7F7" cx="15.0609903" cy="172.6147614" r="1.9140658"/>
                                    </g>
                                    <g>
                                        <circle fill="#CFE7F7" cx="75.4907837" cy="172.6147614" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="75.4907837" cy="214.7242126" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="137.8346405" cy="172.6147614" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="170.1003265" cy="193.6694946" r="1.9140658"/>
                                    </g>
                                    <g>
                                        <circle fill="#CFE7F7" cx="260.0209045" cy="172.6147614" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="260.0209045" cy="214.7242126" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="322.9785156" cy="172.6147614" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="384.0875549" cy="214.2041626" r="1.9140658"/>
                                    </g>
                                    <g>
                                        <circle fill="#CFE7F7" cx="476.5942688" cy="193.6694946" r="1.9140658"/>
                                        <circle fill="#CFE7F7" cx="506.6724548" cy="172.6147614" r="1.9140658"/>
                                    </g>
                                    <g>
                                        <rect x="-75.8487701" y="222.2626495" fill="#E8C3BF" width="632.1829834" height="65.6251144"/>
                                        <g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-53.1090202,243.0724945h-48.1380119c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3499985-3,3-3h48.1380119c1.6500015,0,3,1.3499908,3,3v9.5851898
                                                    C-50.1090202,241.7224884-51.4590187,243.0724945-53.1090202,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M8.4114475,243.0724945h-48.1380081c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3499985-3,3-3H8.4114475c1.6500006,0,3,1.3499908,3,3v9.5851898
                                                    C11.4114475,241.7224884,10.0614481,243.0724945,8.4114475,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M70.2054749,243.0724945H22.0674648c-1.6499996,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500004-3,3-3h48.1380081c1.6500015,0,3,1.3499908,3,3v9.5851898
                                                    C73.2054749,241.7224884,71.8554764,243.0724945,70.2054749,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M131.7259369,243.0724945H83.5879364c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3499985-3,3-3h48.1380005c1.6500092,0,3,1.3499908,3,3v9.5851898
                                                    C134.7259369,241.7224884,133.375946,243.0724945,131.7259369,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M192.9726105,243.0724945h-48.1380157c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3499908,3,3v9.5851898
                                                    C195.9726105,241.7224884,194.6226044,243.0724945,192.9726105,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M254.4930725,243.0724945h-48.1380157c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3499908,3,3v9.5851898
                                                    C257.4930725,241.7224884,256.1430664,243.0724945,254.4930725,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M316.2871094,243.0724945h-48.138031c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3499908,3,3v9.5851898
                                                    C319.2871094,241.7224884,317.9371033,243.0724945,316.2871094,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M377.8075562,243.0724945h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3499908,3,3v9.5851898
                                                    C380.8075562,241.7224884,379.4575806,243.0724945,377.8075562,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M438.5079956,243.0724945h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3499908,3,3v9.5851898
                                                    C441.5079956,241.7224884,440.15802,243.0724945,438.5079956,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M500.0284729,243.0724945h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500061-3,3-3h48.1380005c1.6499939,0,3,1.3499908,3,3v9.5851898
                                                    C503.0284729,241.7224884,501.6784668,243.0724945,500.0284729,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M561.8225098,243.0724945h-48.1380005c-1.6500244,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3499756-3,3-3h48.1380005c1.6500244,0,3,1.3499908,3,3v9.5851898
                                                    C564.8225098,241.7224884,563.4725342,243.0724945,561.8225098,243.0724945z"/>
                                                <path fill="#FFEC9E" d="M623.3429565,243.0724945h-48.1380005c-1.6499634,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6500092,1.3500366-3,3-3h48.1380005c1.6500244,0,3,1.3499908,3,3v9.5851898
                                                    C626.3429565,241.7224884,624.992981,243.0724945,623.3429565,243.0724945z"/>
                                            </g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-53.1090202,284.2227783h-48.1380119c-1.6500015,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3499985-3,3-3h48.1380119c1.6500015,0,3,1.3500061,3,3v9.5851746
                                                    C-50.1090202,282.8728027-51.4590187,284.2227783-53.1090202,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M8.4114475,284.2227783h-48.1380081c-1.6500015,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3499985-3,3-3H8.4114475c1.6500006,0,3,1.3500061,3,3v9.5851746
                                                    C11.4114475,282.8728027,10.0614481,284.2227783,8.4114475,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M70.2054749,284.2227783H22.0674648c-1.6499996,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500004-3,3-3h48.1380081c1.6500015,0,3,1.3500061,3,3v9.5851746
                                                    C73.2054749,282.8728027,71.8554764,284.2227783,70.2054749,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M131.7259369,284.2227783H83.5879364c-1.6500015,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3499985-3,3-3h48.1380005c1.6500092,0,3,1.3500061,3,3v9.5851746
                                                    C134.7259369,282.8728027,133.375946,284.2227783,131.7259369,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M192.9726105,284.2227783h-48.1380157c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851746
                                                    C195.9726105,282.8728027,194.6226044,284.2227783,192.9726105,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M254.4930725,284.2227783h-48.1380157c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380157c1.6499939,0,3,1.3500061,3,3v9.5851746
                                                    C257.4930725,282.8728027,256.1430664,284.2227783,254.4930725,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M316.2871094,284.2227783h-48.138031c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851746
                                                    C319.2871094,282.8728027,317.9371033,284.2227783,316.2871094,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M377.8075562,284.2227783h-48.1380005c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851746
                                                    C380.8075562,282.8728027,379.4575806,284.2227783,377.8075562,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M438.5079956,284.2227783h-48.1380005c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851746
                                                    C441.5079956,282.8728027,440.15802,284.2227783,438.5079956,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M500.0284729,284.2227783h-48.1380005c-1.6499939,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6499939,0,3,1.3500061,3,3v9.5851746
                                                    C503.0284729,282.8728027,501.6784668,284.2227783,500.0284729,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M561.8225098,284.2227783h-48.1380005c-1.6500244,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3499756-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851746
                                                    C564.8225098,282.8728027,563.4725342,284.2227783,561.8225098,284.2227783z"/>
                                                <path fill="#FFEC9E" d="M623.3429565,284.2227783h-48.1380005c-1.6499634,0-3-1.3499756-3-3v-9.5851746
                                                    c0-1.6499939,1.3500366-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851746
                                                    C626.3429565,282.8728027,624.992981,284.2227783,623.3429565,284.2227783z"/>
                                            </g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-21.1183758,263.5793152h-48.1380081c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3499985-3,3-3h48.1380081c1.6499996,0,3,1.3500061,3,3v9.5851898
                                                    C-18.1183758,262.2293091-19.4683762,263.5793152-21.1183758,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M40.402092,263.5793152H-7.7359204c-1.6500001,0-3.0000005-1.3500061-3.0000005-3v-9.5851898
                                                    c0-1.6499939,1.3500004-3,3.0000005-3H40.402092c1.6499977,0,3,1.3500061,3,3v9.5851898
                                                    C43.402092,262.2293091,42.0520897,263.5793152,40.402092,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M102.1961212,263.5793152H54.0581093c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3499985-3,3-3h48.1380119c1.6500015,0,3,1.3500061,3,3v9.5851898
                                                    C105.1961212,262.2293091,103.8461227,263.5793152,102.1961212,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M163.7165833,263.5793152h-48.1380081c-1.6500015,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3499985-3,3-3h48.1380081c1.6500092,0,3,1.3500061,3,3v9.5851898
                                                    C166.7165833,262.2293091,165.3665924,263.5793152,163.7165833,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M224.9632416,263.5793152h-48.1380005c-1.6500092,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3499908-3,3-3h48.1380005c1.6500092,0,3,1.3500061,3,3v9.5851898
                                                    C227.9632416,262.2293091,226.6132507,263.5793152,224.9632416,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M286.4837036,263.5793152h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                    C289.4837036,262.2293091,288.133728,263.5793152,286.4837036,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M348.2777405,263.5793152H300.13974c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                    C351.2777405,262.2293091,349.9277344,263.5793152,348.2777405,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M409.7982178,263.5793152h-48.138031c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                    C412.7982178,262.2293091,411.4482117,263.5793152,409.7982178,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M470.4986572,263.5793152h-48.138031c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500061-3,3-3h48.138031c1.6499939,0,3,1.3500061,3,3v9.5851898
                                                    C473.4986572,262.2293091,472.1486511,263.5793152,470.4986572,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M532.019104,263.5793152h-48.1380005c-1.6499939,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500061-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                    C535.019104,262.2293091,533.6691284,263.5793152,532.019104,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M593.8131714,263.5793152h-48.1380615c-1.6499634,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3500366-3,3-3h48.1380615c1.6499634,0,3,1.3500061,3,3v9.5851898
                                                    C596.8131714,262.2293091,595.4631348,263.5793152,593.8131714,263.5793152z"/>
                                                <path fill="#FFEC9E" d="M655.3336182,263.5793152h-48.1380005c-1.6500244,0-3-1.3500061-3-3v-9.5851898
                                                    c0-1.6499939,1.3499756-3,3-3h48.1380005c1.6500244,0,3,1.3500061,3,3v9.5851898
                                                    C658.3336182,262.2293091,656.9836426,263.5793152,655.3336182,263.5793152z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="-76.9453125" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="-76.9453125" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="-44.6796341" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="-14.6014566" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="-14.6014566" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="17.6642246" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="45.8283348" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="45.8283348" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="78.094017" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="108.1721954" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="108.1721954" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="140.4378662" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="169.1188354" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="169.1188354" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="201.3845215" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="231.4626923" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="231.4626923" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="263.728363" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="293.0162659" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="293.0162659" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="325.2819519" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="355.3601074" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="355.3601074" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="387.6257935" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="414.6661377" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="414.6661377" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="446.9318237" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="477.0100098" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="477.0100098" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="509.2756653" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                        <g>
                                            <circle fill="#FFEC9E" cx="537.7432251" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="537.7432251" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="570.0089111" cy="255.8955231" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="600.0870972" cy="234.8408051" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="600.0870972" cy="276.9502563" r="1.9140658"/>
                                            <circle fill="#FFEC9E" cx="632.3527832" cy="255.8955231" r="1.9140658"/>
                                        </g>
                                    </g>
                                </g>

                                <linearGradient id="laser_1" gradientUnits="userSpaceOnUse" x1="139.4438171" y1="144.8695526" x2="139.4438171" y2="359.1763306">
                                    <stop  offset="0" style="stop-color:#EC1C24;stop-opacity:0.5"/>
                                    <stop  offset="0.2044815" style="stop-color:#EC1F27;stop-opacity:0.3977592"/>
                                    <stop  offset="0.3442763" style="stop-color:#ED282F;stop-opacity:0.3278618"/>
                                    <stop  offset="0.4647565" style="stop-color:#EE373E;stop-opacity:0.2676218"/>
                                    <stop  offset="0.5742741" style="stop-color:#F04D53;stop-opacity:0.212863"/>
                                    <stop  offset="0.676409" style="stop-color:#F2696E;stop-opacity:0.1617955"/>
                                    <stop  offset="0.7731353" style="stop-color:#F58B8F;stop-opacity:0.1134323"/>
                                    <stop  offset="0.8655362" style="stop-color:#F9B4B7;stop-opacity:0.0672319"/>
                                    <stop  offset="0.9522175" style="stop-color:#FDE2E3;stop-opacity:0.0238913"/>
                                    <stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/>
                                </linearGradient>
                                <polyline id="laser-1" opacity="0.88" fill="url(#laser_1)" stroke="#EC1C24" stroke-width="5" stroke-miterlimit="10" points="
                                    243.2819519,329.9153748 139.4438171,142.1311646 35.6056938,329.9153748 		"/>
                                <g>
                                    <path fill="#7D9FB9" d="M263.8207397,66.7668533c-4.1973267,2.7982101-63.6593323,33.5785522-63.6593323,33.5785522H63.0506439
                                        l12.5902863,37.7758713l52.4681549,15.3985138c0,0,5.5947571,4.1856384,11.1911926,4.1856384
                                        c5.5964203,0,10.6470795-3.4877625,10.6470795-3.4877625l18.7341461-2.8048706l20.9866028-32.179451h55.9642487
                                        l4.8968811-15.3901749l34.9776611-2.7982101L263.8207397,66.7668533z"/>
                                    <path fill="#7D9FB9" d="M247.742691,49.2863579c-2.0986633,1.3991051-39.1749878,12.5919571-39.1749878,12.5919571
                                        l-9.7937317-6.2959785l-2.0986633-6.2959785L74.9530487,40.1921654L63.0589752,96.8559723h136.4145355l62.2602539-31.4798889
                                        L247.742691,49.2863579z"/>
                                </g>
                            </g>
                        </g>
                        <circle fill="transparent" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="141.7322845" cy="140.9272919" r="138.401825"/>
                        </svg>
                </div>
                <div class="col-md-7 d-flex justify-content-center justify-content-md-start align-items-center">
                    <h4>
                        深層：以46°C射頻凋亡脂肪
                    </h4>
                </div>
            </div>

            <div class="position-relative row mb-3"
                 data-aos="fade-up"
                 data-aos-duration="1000">
                <div class="col-md-5 d-flex justify-content-center justify-content-md-end">
                    <svg class="icon mb-2 mb-md-0" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 283.4645691 283.4645691" enable-background="new 0 0 283.4645691 283.4645691" xml:space="preserve">
                        <g>
                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="141.3918152" y1="4.7653222" x2="141.3918152" y2="281.5689697">
                                <stop  offset="0.2229202" style="stop-color:#FCDFD6"></stop>
                                <stop  offset="0.3388689" style="stop-color:#FDEBE6"></stop>
                                <stop  offset="0.517253" style="stop-color:#FFFAF8"></stop>
                                <stop  offset="0.6432572" style="stop-color:#FFFFFF"></stop>
                            </linearGradient>
                            <circle fill="url(#SVGID_1_)" cx="141.3918152" cy="143.1671448" r="138.401825"/>
                            <g>
                                <defs>
                                    <circle id="SVGID_2_" cx="141.3918152" cy="143.1671448" r="138.401825"/>
                                </defs>
                                <clipPath id="SVGID_3_">
                                    <use xlink:href="#SVGID_2_"  overflow="visible"/>
                                </clipPath>
                                <g clip-path="url(#SVGID_3_)">
                                    <g>
                                        <polygon fill="#E8C3BF" points="463.6549377,161.9238586 -122.9039154,161.9238586 -122.9039154,209.7499695
                                            -150.4256744,209.7499695 -150.4256744,279.4091797 436.1333008,279.4091797 436.1333008,210.8886108 463.6549377,210.8886108
                                                            "/>
                                        <rect x="-126.2881622" y="158.7161255" fill="#CF787A" width="586.5588989" height="4.3466759"/>
                                        <g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-102.0217743,177.4506531h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3499908,3,3v5.6285553
                                                    C-99.0217743,176.1006622-100.3717804,177.4506531-102.0217743,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M-44.9411888,177.4506531h-44.2309151c-1.6500015,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3499985-3,3-3h44.2309151c1.6500015,0,3,1.3499908,3,3v5.6285553
                                                    C-41.9411888,176.1006622-43.2911873,177.4506531-44.9411888,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M12.3932209,177.4506531H-31.837698c-1.6499996,0-3.0000019-1.3499908-3.0000019-3v-5.6285553
                                                    c0-1.6500092,1.3500023-3,3.0000019-3h44.2309189c1.6499996,0,3,1.3499908,3,3v5.6285553
                                                    C15.3932209,176.1006622,14.0432205,177.4506531,12.3932209,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M69.4738083,177.4506531H25.2428913c-1.6499996,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500004-3,3-3h44.2309189c1.6500015,0,3,1.3499908,3,3v5.6285553
                                                    C72.4738083,176.1006622,71.1238098,177.4506531,69.4738083,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M126.300354,177.4506531H82.0694351c-1.6500015,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3499985-3,3-3h44.2309189c1.6500015,0,3,1.3499908,3,3v5.6285553
                                                    C129.300354,176.1006622,127.9503555,177.4506531,126.300354,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M183.3809509,177.4506531h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3499908,3,3v5.6285553
                                                    C186.3809509,176.1006622,185.0309448,177.4506531,183.3809509,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M240.7153473,177.4506531H196.484436c-1.6500092,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3499908-3,3-3h44.2309113c1.6500092,0,3,1.3499908,3,3v5.6285553
                                                    C243.7153473,176.1006622,242.3653564,177.4506531,240.7153473,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M297.795929,177.4506531h-44.2309113c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309113c1.6500244,0,3,1.3499908,3,3v5.6285553
                                                    C300.795929,176.1006622,299.4459534,177.4506531,297.795929,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M354.1156921,177.4506531h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3499908,3,3v5.6285553
                                                    C357.1156921,176.1006622,355.765686,177.4506531,354.1156921,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M411.1962891,177.4506531h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3499908,3,3v5.6285553
                                                    C414.1962891,176.1006622,412.846283,177.4506531,411.1962891,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M468.5306702,177.4506531h-44.230896c-1.6500244,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3499756-3,3-3h44.230896c1.6500244,0,3,1.3499908,3,3v5.6285553
                                                    C471.5306702,176.1006622,470.1806946,177.4506531,468.5306702,177.4506531z"/>
                                                <path fill="#FFEC9E" d="M525.6112671,177.4506531h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6500092,1.3500061-3,3-3h44.2309265c1.6500244,0,3,1.3499908,3,3v5.6285553
                                                    C528.6112671,176.1006622,527.2612915,177.4506531,525.6112671,177.4506531z"/>
                                            </g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-102.0217743,208.1540985h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285553
                                                    C-99.0217743,206.8041077-100.3717804,208.1540985-102.0217743,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M-44.9411888,208.1540985h-44.2309151c-1.6500015,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3499985-3,3-3h44.2309151c1.6500015,0,3,1.3500061,3,3v5.6285553
                                                    C-41.9411888,206.8041077-43.2911873,208.1540985-44.9411888,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M12.3932209,208.1540985H-31.837698c-1.6499996,0-3.0000019-1.3499908-3.0000019-3v-5.6285553
                                                    c0-1.6499939,1.3500023-3,3.0000019-3h44.2309189c1.6499996,0,3,1.3500061,3,3v5.6285553
                                                    C15.3932209,206.8041077,14.0432205,208.1540985,12.3932209,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M69.4738083,208.1540985H25.2428913c-1.6499996,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500004-3,3-3h44.2309189c1.6500015,0,3,1.3500061,3,3v5.6285553
                                                    C72.4738083,206.8041077,71.1238098,208.1540985,69.4738083,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M126.300354,208.1540985H82.0694351c-1.6500015,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3499985-3,3-3h44.2309189c1.6500015,0,3,1.3500061,3,3v5.6285553
                                                    C129.300354,206.8041077,127.9503555,208.1540985,126.300354,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M183.3809509,208.1540985h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285553
                                                    C186.3809509,206.8041077,185.0309448,208.1540985,183.3809509,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M240.7153473,208.1540985H196.484436c-1.6500092,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3499908-3,3-3h44.2309113c1.6500092,0,3,1.3500061,3,3v5.6285553
                                                    C243.7153473,206.8041077,242.3653564,208.1540985,240.7153473,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M297.795929,208.1540985h-44.2309113c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309113c1.6500244,0,3,1.3500061,3,3v5.6285553
                                                    C300.795929,206.8041077,299.4459534,208.1540985,297.795929,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M354.1156921,208.1540985h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285553
                                                    C357.1156921,206.8041077,355.765686,208.1540985,354.1156921,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M411.1962891,208.1540985h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285553
                                                    C414.1962891,206.8041077,412.846283,208.1540985,411.1962891,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M468.5306702,208.1540985h-44.230896c-1.6500244,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3499756-3,3-3h44.230896c1.6500244,0,3,1.3500061,3,3v5.6285553
                                                    C471.5306702,206.8041077,470.1806946,208.1540985,468.5306702,208.1540985z"/>
                                                <path fill="#FFEC9E" d="M525.6112671,208.1540985h-44.2309265c-1.6499939,0-3-1.3499908-3-3v-5.6285553
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6500244,0,3,1.3500061,3,3v5.6285553
                                                    C528.6112671,206.8041077,527.2612915,208.1540985,525.6112671,208.1540985z"/>
                                            </g>
                                            <g>
                                                <path fill="#FFEC9E" d="M-72.3398666,192.7514038h-44.2309189c-1.6500015,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3499985-3,3-3h44.2309189c1.6499939,0,3,1.3500061,3,3v5.6285706
                                                    C-69.3398666,191.4013977-70.6898727,192.7514038-72.3398666,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M-15.2592802,192.7514038H-59.4902c-1.6499977,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500023-3,3-3h44.2309189c1.6499996,0,3,1.3500061,3,3v5.6285706
                                                    C-12.2592802,191.4013977-13.6092806,192.7514038-15.2592802,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M42.0751266,192.7514038H-2.1557915c-1.6499999,0-2.9999998-1.3500061-2.9999998-3v-5.6285706
                                                    c0-1.6499939,1.3499999-3,2.9999998-3h44.2309189c1.6500015,0,3,1.3500061,3,3v5.6285706
                                                    C45.0751266,191.4013977,43.7251282,192.7514038,42.0751266,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M99.1557159,192.7514038H54.9247971c-1.6499977,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500023-3,3-3h44.2309189c1.6500015,0,3,1.3500061,3,3v5.6285706
                                                    C102.1557159,191.4013977,100.8057175,192.7514038,99.1557159,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M155.982254,192.7514038h-44.2309113c-1.6500015,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3499985-3,3-3h44.2309113c1.6500092,0,3,1.3500061,3,3v5.6285706
                                                    C158.982254,191.4013977,157.6322632,192.7514038,155.982254,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M213.062851,192.7514038h-44.2309265c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285706
                                                    C216.062851,191.4013977,214.7128448,192.7514038,213.062851,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M270.3972473,192.7514038h-44.2309113c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309113c1.6500244,0,3,1.3500061,3,3v5.6285706
                                                    C273.3972473,191.4013977,272.0472717,192.7514038,270.3972473,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M327.4778442,192.7514038h-44.2309265c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285706
                                                    C330.4778442,191.4013977,329.1278381,192.7514038,327.4778442,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M383.7975769,192.7514038h-44.230896c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.230896c1.6500244,0,3,1.3500061,3,3v5.6285706
                                                    C386.7975769,191.4013977,385.4476013,192.7514038,383.7975769,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M440.8781738,192.7514038h-44.2309265c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285706
                                                    C443.8781738,191.4013977,442.5281677,192.7514038,440.8781738,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M498.2125854,192.7514038h-44.2309265c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.2309265c1.6499939,0,3,1.3500061,3,3v5.6285706
                                                    C501.2125854,191.4013977,499.8625793,192.7514038,498.2125854,192.7514038z"/>
                                                <path fill="#FFEC9E" d="M555.2931519,192.7514038h-44.230896c-1.6499939,0-3-1.3500061-3-3v-5.6285706
                                                    c0-1.6499939,1.3500061-3,3-3h44.230896c1.6500244,0,3,1.3500061,3,3v5.6285706
                                                    C558.2931519,191.4013977,556.9431763,192.7514038,555.2931519,192.7514038z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="-123.9213181" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-123.9213181" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-93.9842224" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-66.076767" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-66.076767" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-36.1396675" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="-10.0081358" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="-10.0081358" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="19.9289589" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="47.836422" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="47.836422" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="77.7735138" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="104.3845978" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="104.3845978" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="134.321701" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="162.2291565" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="162.2291565" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="192.1662445" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="219.3404541" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="219.3404541" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="249.2775574" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="277.1849976" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="277.1849976" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="307.1221008" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="332.210968" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="332.210968" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="362.1480713" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="390.0555115" cy="171.3087616" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="390.0555115" cy="202.7278442" rx="1.7759293" ry="1.4281405"/>
                                            <ellipse fill="#FFEC9E" cx="419.9926147" cy="187.0183105" rx="1.7759293" ry="1.4281405"/>
                                        </g>
                                        <g>
                                            <ellipse fill="#FFEC9E" cx="446.4057007" cy="171.3087616" rx="1.7759293" ry="1.4281405"></ellipse>
                                            <ellipse fill="#FFEC9E" cx="446.4057007" cy="202.7278442" rx="1.7759293" ry="1.4281405"></ellipse>
                                            <ellipse fill="#FFEC9E" cx="476.342804" cy="187.0183105" rx="1.7759293" ry="1.4281405"></ellipse>
                                            <ellipse fill="#FFEC9E" cx="504.2502747" cy="171.3087616" rx="1.7759293" ry="1.4281405"></ellipse>
                                            <ellipse fill="#FFEC9E" cx="504.2502747" cy="202.7278442" rx="1.7759293" ry="1.4281405"></ellipse>
                                            <ellipse fill="#FFEC9E" cx="534.1873779" cy="187.0183105" rx="1.7759293" ry="1.4281405"></ellipse>
                                        </g>
                                    </g>

                                    <linearGradient id="laser_2" gradientUnits="userSpaceOnUse" x1="138.103363" y1="116.0220032" x2="138.103363" y2="230.2849731">
                                        <stop  offset="0" style="stop-color:#EC1C24;stop-opacity:0.5"></stop>
                                        <stop  offset="0.2044815" style="stop-color:#EC1F27;stop-opacity:0.3977592"></stop>
                                        <stop  offset="0.3442763" style="stop-color:#ED282F;stop-opacity:0.3278618"></stop>
                                        <stop  offset="0.4647565" style="stop-color:#EE373E;stop-opacity:0.2676218"></stop>
                                        <stop  offset="0.5742741" style="stop-color:#F04D53;stop-opacity:0.212863"></stop>
                                        <stop  offset="0.676409" style="stop-color:#F2696E;stop-opacity:0.1617955"></stop>
                                        <stop  offset="0.7731353" style="stop-color:#F58B8F;stop-opacity:0.1134323"></stop>
                                        <stop  offset="0.8655362" style="stop-color:#F9B4B7;stop-opacity:0.0672319"></stop>
                                        <stop  offset="0.9522175" style="stop-color:#FDE2E3;stop-opacity:0.0238913"></stop>
                                        <stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0"></stop>
                                    </linearGradient>

                                    <polyline id="laser-2" opacity="0.88" fill="url(#laser_2)" stroke="#EC1C24" stroke-width="5" stroke-miterlimit="10" points="
                                        263.5918579,214.6837769 138.103363,114.5619583 12.6148443,214.6837769">
                                    </polyline>

                                    <g>
                                        <path fill="#7D9FB9" d="M243.0568237,46.2632179c-3.5655975,2.3770676-54.0783386,28.5248375-54.0783386,28.5248375H72.5034714
                                            l10.6954041,32.0904465l44.5714722,13.0809631c0,0,4.7527237,3.5556946,9.5068588,3.5556946
                                            c4.7541504,0,9.0446625-2.9628448,9.0446625-2.9628448l15.9145813-2.3827286l17.8280182-27.3363037h47.5413971
                                            l4.1598816-13.0738907l29.7133789-2.3770676L243.0568237,46.2632179z"></path>
                                        <path fill="#7D9FB9" d="M229.3985901,31.4136105c-1.7828064,1.1885357-33.2789917,10.6968136-33.2789917,10.6968136
                                            l-8.3197327-5.3484077l-1.7828064-5.3484058L82.6145172,23.6881313L72.5105515,71.8237991h115.8835831l52.889801-26.7420387
                                            L229.3985901,31.4136105z"></path>
                                    </g>
                                    <polygon class="arrow-up" fill="#F9F3A2" points="100.4974518,243.0466309 85.5062256,213.6837769 70.5149384,243.0466309
                                        79.2441864,243.0466309 79.2441864,261.335022 91.7682037,261.335022 91.7682037,243.0466309"></polygon>
                                    <polygon class="arrow-up" fill="#F9F3A2" points="207.6917725,243.0466309 192.7005463,213.6837769 177.709259,243.0466309
                                        186.4385071,243.0466309 186.4385071,261.335022 198.9625244,261.335022 198.9625244,243.0466309"></polygon>
                                    <polygon class="arrow-up" fill="#F9F3A2" points="154.1017914,243.0466309 139.1105652,213.6837769 124.119278,243.0466309
                                        132.848526,243.0466309 132.848526,261.335022 145.3725433,261.335022 145.3725433,243.0466309"></polygon>
                                </g>
                            </g>
                            <circle fill="transparent" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="141.3918152" cy="143.1671448" r="138.401825"></circle>
                        </g>
                        </svg>
                </div>
                <div class="col-md-7 d-flex justify-content-center justify-content-md-start align-items-center">
                    <h4>
                        淺層：雕塑修形
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vivus from "vivus";

    export default {
        name: "MonoSculptingTechnology",
        data () {
            return {
                patternVivus: null,
                isPatternVivusDone: false,
            };
        },
        mounted () {
            this.patternVivus = new Vivus(
                "pattern-5",
                {
                    type: "delayed",
                    start: "inViewport",
                    duration: 150
                },
                () => {
                    this.isPatternVivusDone = true;
                }
            );
        }
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .technology {
        background-color: $background-color-alt;
        color: $text-color-alt;

        .icon {
            max-width: 160px;
            width: 100%;
        }

        .pattern {
            top: 1rem;
            left: 0;
            bottom: 1rem;

            svg {
                &#pattern-5 {
                    path {
                        transition: fill 250ms;
                    }

                    &.done {
                        path {
                            fill: $background-color;
                        }
                    }
                }
            }
        }
    }

    #laser-1 {
        // Disabled for potential Chrome renderer thread crash in Windows
        /*stroke-dasharray: 300px;
        stroke-dashoffset: 300px;*/

        animation: flashing 2000ms infinite;
        animation-direction: alternate;
    }

    #laser-2 {
        // Disabled for potential Chrome renderer thread crash in Windows
        /*stroke-dasharray: 300px;
        stroke-dashoffset: 300px;*/

        animation: flashing 2000ms infinite;
        animation-direction: alternate;
    }
    
    .arrow-up {
        animation: fadeInUp 1250ms infinite;
    }

    @keyframes flashing {
        0% {
            opacity: 0;
        }

        100% {
            //stroke-dasharray: 0;
            opacity: 1;
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
</style>
